import { Button, Card, Col, Modal, Row } from "antd";
import { isEmpty } from "lodash";
import React, { Component, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import settingsInfo from "../../../../config/settings";
import {
  GenericSettings,
  ResultsTableSettings,
  TechnicalSpecificationConfig,
} from "../../../../generated/axios";
import { ErrorHandler } from "../../../../shared/components/ErrorHandler";
import { getApplicationTypes } from "../../../../shared/store/applicationType/actions";
import {
  resetUpdateInfo,
  saveBounds,
  updateGenerics,
  updateResultTableSettings,
  updateTechSpecSettings,
} from "../../../../shared/store/settings/actions";
import { UpdateInfo } from "../../../../shared/store/settings/types";
import { resetSettingsTemp } from "../../../../shared/store/settingsTmp/actions";
import { LocalStore } from "../../../../shared/store/settingsTmp/reducer";
import { IStore } from "../../../../shared/store/types";
import { UIModalSettings } from "../../../../shared/store/ui/types";
import { Any } from "../../../../types/Any";
import { Callback } from "../../../../types/Callback";
import { capabilityMap } from "../../../capability";
import ItemTechnicalSpecification from "../../../itemTechnicalSpecification/ItemTechnicalSpecification";
import SectionNumber from "../../components/Section/SectionNumber";
import { Bounds } from "./Bounds";
import CustomizeTechnicalSpecificationModal from "./CustomizeTechnicalSpecificationModal";
import FieldList from "./FieldList";
import Languages from "./Languages";
import { displayInfo } from "./lib";
import { LoadApplicationTypes } from "./LoadApplicationTypes";
import "./settingsModal.scss";

interface OwnProps {
  onCancel: Callback;
}

interface ReduxProps extends UIModalSettings {
  itemId?: number;
  projectId?: number;
  updatedData: LocalStore;
  updateInfo?: UpdateInfo;
  total: number;
  isComplete: boolean;
  parts: {
    ui: boolean;
    resultFields: boolean;
    techSpecCol: boolean;
    techSpecFull: boolean;
  };
  capabilities: string[];
}

interface DispatchProps {
  getApplicationTypes: (portfolioIdFromUrl?: string) => void;
  resetSettingsTemp: Callback;
  resetUpdateInfo: Callback;
  saveBounds: Callback;
  updateGenerics: (
    settings: GenericSettings,
    params: { itemId?: number; projectId?: number }
  ) => void;
  updateResultTableSettings: (
    settings: ResultsTableSettings,
    params: { itemId?: number; projectId?: number }
  ) => void;
  updateTechSpecSettings: (
    settings: Partial<TechnicalSpecificationConfig>,
    params: { itemId?: number; projectId?: number }
  ) => void;
}

export const SETTING_FIELDS = {
  UI_INTERFACE_LANGUAGE: "ui_interface_language",
  UI_MEASUREMENT_SYSTEM: "ui_measurement_system",
  RESULTS_FIELD_LIST: "results_field_list",
  TECHNICAL_SPECIFICATION_COL: "technical_specification",
  TECHNICAL_SPECIFICATION_FULL_MODAL: "technical_specification_full",
};

interface Props extends ReduxProps, DispatchProps, OwnProps {}

class SettingsModal extends Component<Props> {
  formRef: React.Ref<Any>;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.formRef = React.createRef();
  }

  // next step could be managing the visible using the state
  static getDerivedStateFromProps(props: Props) {
    const { updateInfo, scope } = props;

    if (updateInfo) {
      const { generics, resultTable, techSpec } = updateInfo;
      if (scope === settingsInfo.scopes.ITEM) {
        // result table page?
        if (resultTable) {
          props.resetUpdateInfo();
        }
      } else if (!!generics && !!resultTable && !!techSpec) {
        props.resetUpdateInfo();
        props.onCancel();
      }
    }

    return null;
  }

  saveHandler = () => {
    const {
      itemId,
      projectId,
      updatedData,
      resetSettingsTemp,
      updateGenerics,
      updateResultTableSettings,
      updateTechSpecSettings,
      saveBounds,
    } = this.props;

    this.validate((errors: Any, values: Any) => {
      if (!errors) {
        if (updatedData.languages) {
          updateGenerics(updatedData.languages, { itemId, projectId });
        }
        updateResultTableSettings(
          { fields: updatedData.fields, orientation: updatedData.orientation },
          {
            itemId,
            projectId,
          }
        );
        if (!isEmpty(values)) {
          // Remove "at" prefix from keys of fields object
          const fields = values.fields;
          if (fields) {
            values.fields = Object.keys(fields).reduce((acc, key) => {
              const renamedKey = key.replace(/^at/, "");
              acc[renamedKey] = fields[key];
              return acc;
            }, {});
          }

          updateTechSpecSettings(values, {
            itemId,
            projectId,
          });
        }
        saveBounds();
        getApplicationTypes();
        resetSettingsTemp();
      }
    });
  };

  validate = (callback: (errors: Any, values: Any) => void) => {
    const {
      parts: { techSpecCol },
    } = this.props;
    if (techSpecCol) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const form = this.formRef?.current.getWrappedInstance().props.form;
      form?.validateFields(callback);
    } else {
      callback(null, {});
    }
  };

  onCancel = () => {
    this.props.onCancel();
  };

  saveButton: ReactNode = (
    <Row>
      <Col span={24}>
        <Row type="flex" justify="end">
          <Col>
            <Button
              htmlType="submit"
              type="primary"
              className="text-transform--uppercase"
              onClick={this.saveHandler}
              data-test="modal-button-save"
            >
              <FormattedMessage id="save" defaultMessage="Save" />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  render() {
    const {
      isComplete,
      itemId,
      parts,
      projectId,
      scope,
      sections,
      title,
      visible,
      capabilities,
    } = this.props;

    const { ui, resultFields, techSpecCol, techSpecFull } = parts;
    const length = sections.length - 1;

    const showBounds =
      scope !== settingsInfo.scopes.ITEM &&
      capabilities.includes(capabilityMap.SETTINGS_BOUNDS_ENABLE);

    const showTechnicalSpecification =
      techSpecCol &&
      capabilities.includes(capabilityMap.SETTINGS_RES_TABLE_CUSTOMIZE_FIELDS);

    /**
     * List of sections/columns to display
     */
    const columns = [
      ui && (
        <Languages
          sections={sections}
          scope={scope}
          itemId={itemId}
          projectId={projectId}
          hasDataToDisplay={isComplete}
        />
      ),
      resultFields && (
        <FieldList
          scope={scope}
          itemId={itemId}
          projectId={projectId}
          hasDataToDisplay={isComplete}
        />
      ),
      showTechnicalSpecification && (
        <CustomizeTechnicalSpecificationModal
          wrappedComponentRef={this.formRef}
          scope={scope}
          itemId={itemId}
          projectId={projectId}
          hasDataToDisplay={isComplete}
        />
      ),
      showBounds && <Bounds key={1} />,
    ].filter((el) => el);

    return (
      <Modal
        visible={visible}
        centered={true}
        onCancel={this.onCancel}
        className="settings"
        destroyOnClose={true}
        title={
          techSpecFull ? (
            <FormattedMessage
              id="customize technical specification"
              defaultMessage="Customize Technical Specification"
            />
          ) : (
            title
          )
        }
        footer={!techSpecFull ? this.saveButton : null}
      >
        <LoadApplicationTypes cond={showTechnicalSpecification || showBounds} />
        <div className="settings__form">
          <Row gutter={16} type="flex" justify="center">
            {columns.map((content, i) => (
              <Col className="settings__card" span={0} key={i}>
                <Card bordered={false} className="settings__content">
                  {length > 0 && (
                    <SectionNumber n={i + 1} total={columns.length} />
                  )}
                  <ErrorHandler>{content}</ErrorHandler>
                </Card>
              </Col>
            ))}

            {techSpecFull && (
              <Col>
                {/*
                   Card and SectionNumber are not necessary because it appears stand-alone.
                   More over it has a different layout
                 */}
                <ErrorHandler>
                  <ItemTechnicalSpecification itemId={itemId} />
                </ErrorHandler>
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    );
  }
}

export default connect<ReduxProps, DispatchProps, OwnProps, IStore>(
  (state: IStore) => {
    const itemId = state.item.id;
    const projectId = state.project.id;
    const updatedData = state.settingsTmp;
    const updateInfo = state.settings.updateInfo;
    const info = displayInfo(state);

    return {
      ...state.ui.modalSettings,
      ...info,
      itemId,
      projectId,
      updatedData,
      updateInfo,
      capabilities: state.capabilities.list ?? [],
    };
  },
  {
    getApplicationTypes,
    resetSettingsTemp,
    resetUpdateInfo,
    saveBounds,
    updateGenerics,
    updateResultTableSettings,
    updateTechSpecSettings,
  }
)(SettingsModal);
