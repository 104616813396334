import { useSelector } from "react-redux";
import { ApplicationType } from "../../../../../generated/axios";
import { applicationTypeSelector } from "../../../../../shared/store/applicationType/selectors";

/**
 * @param id ApplicationType id
 * @returns ApplicationType object
 */
export const useApplicationType = (id: number): ApplicationType | undefined => {
  const applicationTypes = useSelector(applicationTypeSelector);
  let applicationType: ApplicationType | undefined;
  Object.keys(applicationTypes).forEach((group) =>
    applicationTypes[group].find((_applicationType: ApplicationType) => {
      if (_applicationType.id === id) {
        applicationType = _applicationType;
        return true;
      }
      return false;
    })
  );
  return applicationType;
};
