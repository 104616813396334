import { CheckboxOptionType } from "antd/lib/checkbox";
import { useEffect, useState } from "react";
import { getReportFields } from "../../../../../shared/store/settings/actions";

export const useReportFields = (currentApplicationType: number) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<CheckboxOptionType[]>([]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    getReportFields(currentApplicationType)
      .then((data) => {
        const optionsList = data.map((reportData) => ({
          value: reportData.fieldId,
          label: reportData.label,
        }));
        setOptions(optionsList);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [currentApplicationType, setError, setLoading, setOptions]);

  return [options, loading, error] as [CheckboxOptionType[], boolean, boolean];
};
