import { Alert, Checkbox, Form, Spin, Switch } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { ApplicationTypeSectionTitle } from "./ApplicationTypeSectionTitle";
import { useApplicationType } from "./hooks/useApplicationType";
import { useReportFields } from "./hooks/useReportFields";

interface Props {
  currentApplicationType: number;
  getFieldValue: WrappedFormUtils["getFieldValue"];
  setFieldsValue: WrappedFormUtils["setFieldsValue"];
}
export function ApplicationTypeFieldsForm({
  currentApplicationType,
  getFieldValue,
  setFieldsValue,
}: Readonly<Props>) {
  const applicationType = useApplicationType(currentApplicationType);

  const hiddenFieldName = `fields.at${currentApplicationType}`;

  const [defaultChecked, setDefaultChecked] = React.useState(
    undefined === getFieldValue(hiddenFieldName)
  );

  const [options, loading, error] = useReportFields(currentApplicationType);

  const allValues = useMemo(
    () => options.map((option) => option.value),
    [options]
  );

  const retriveInitialValueFrom = () => {
    const value = getFieldValue(hiddenFieldName);
    if (value === undefined) return allValues; // All values
    else return value;
  };

  const onChange = useCallback(
    (values: string[]) => {
      const checked = values.length === allValues.length;
      setDefaultChecked(checked);
      setFieldsValue({ [hiddenFieldName]: checked ? undefined : values });
    },
    [allValues, hiddenFieldName, setDefaultChecked, setFieldsValue]
  );

  const onDefaultCheckedChange = useCallback(
    (checked: boolean) => {
      setDefaultChecked(checked);
      setFieldsValue({ [hiddenFieldName]: checked ? undefined : allValues });
    },
    [allValues, hiddenFieldName, setDefaultChecked, setFieldsValue]
  );

  return (
    <div
      className="application-type-form-animation"
      key={currentApplicationType}
    >
      <ApplicationTypeSectionTitle applicationType={applicationType} />
      {error && (
        <Alert
          type={"error"}
          message={
            <FormattedMessage
              id={"error-loading-fields-for-application-type"}
              defaultMessage={
                "There was an error loading the field list for the selected application type"
              }
            />
          }
          style={{ maxWidth: "100%" }}
        />
      )}
      {!error && loading && <Spin size="small" />}
      {!error && !loading && (
        <div>
          <Form.Item
            className="bounds__label"
            label={
              <FormattedMessage
                id="setToDefaults"
                defaultMessage="Set default values"
              />
            }
          >
            <Switch
              checked={defaultChecked}
              onChange={onDefaultCheckedChange}
            />
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                id="Select fields to display"
                defaultMessage="Select fields to display"
              />
            }
            className="customize-modal__label"
          >
            <Checkbox.Group
              onChange={onChange}
              defaultValue={retriveInitialValueFrom()}
              options={options}
              className={"vertical"}
              disabled={defaultChecked}
            />
          </Form.Item>
        </div>
      )}
    </div>
  );
}
