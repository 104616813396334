import { Col, Form, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ApplicationTypeBoundsForm } from "./bounds/ApplicationTypeBoundsForm";
import { useBoundsSettings } from "./hooks/useBoundsSettings";
import { SelectApplicationType } from "./SelectApplicationType";

/**
 * Allows to edit bounds for each project
 * First select a project, then edit its bounds
 */
export const Bounds: React.FC = () => {
  const [currentApplicationType, setCurrentApplicationType] = React.useState<
    number | undefined
  >(undefined);
  const boundsSettings = useBoundsSettings();

  return (
    <Row>
      <Col span={24} className="bounds__container">
        <Row>
          <Col className="bounds__section-title margin-bottom--40" span={24}>
            <FormattedMessage id="bounds" defaultMessage="Bounds" />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form>
              <SelectApplicationType setCurrent={setCurrentApplicationType} />
              {currentApplicationType && (
                <ApplicationTypeBoundsForm
                  {...{
                    boundsSettings,
                    currentApplicationType,
                  }}
                />
              )}
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
