import { Form, Select } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { ApplicationType } from "../../../../generated/axios";
import { applicationTypeSelector } from "../../../../shared/store/applicationType/selectors";

interface Props {
  setCurrent: (value: number) => void;
}

export function SelectApplicationType({ setCurrent }: Readonly<Props>) {
  const applicationTypes = useSelector(applicationTypeSelector);

  return (
    <Form.Item
      className="bounds__label"
      label={
        <FormattedMessage
          id="select_application type"
          defaultMessage="Select application type"
        />
      }
    >
      <Select
        onChange={(value: number) => setCurrent(value)}
        style={{ width: "100%", minWidth: 306 }}
      >
        {Object.keys(applicationTypes).map((group) => (
          <Select.OptGroup label={group} key={group}>
            {applicationTypes[group]?.map(
              (applicationType: ApplicationType) => (
                <Select.Option
                  value={applicationType.id}
                  key={applicationType.id}
                >
                  {applicationType.name}
                </Select.Option>
              )
            )}
          </Select.OptGroup>
        ))}
      </Select>
    </Form.Item>
  );
}
