import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../shared/store/hooks";
import { getBounds } from "../../../../../shared/store/settings/actions";
import { boundsSelector } from "../../../../../shared/store/settings/selectors";

/**
 * @returns BoundsConfig Bounds settings for each project
 */
export const useBoundsSettings = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getBounds());
  }, [dispatch]);

  return useSelector(boundsSelector);
};
