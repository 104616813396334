{
  "name": "@intesys/alfalaval-aps",
  "version": "0.31.0",
  "private": true,
  "dependencies": {
    "@reduxjs/toolkit": "^1.9.7",
    "antd": "^3.26.20",
    "axios": "^0.27.2",
    "bowser": "^2.11.0",
    "classnames": "^2.3.2",
    "expression-eval": "^1.4.0",
    "file-saver": "^2.0.5",
    "lodash": "^4.17.21",
    "moment": "^2.29.4",
    "numeral": "^2.0.6",
    "react": "^16.14.0",
    "react-beautiful-dnd": "^13.1.1",
    "react-dom": "^16.14.0",
    "react-intl": "^2.9.0",
    "react-redux": "^8.1.3",
    "react-router-dom": "^5.3.4",
    "react-svg": "^16.1.28",
    "redux": "^4.2.1",
    "redux-promise-middleware": "^6.1.3",
    "redux-thunk": "^2.4.2",
    "reselect": "^4.1.8",
    "universal-cookie": "^6.1.1"
  },
  "browserslist": [
    "> 0.25%",
    "last 2 versions",
    "not dead"
  ],
  "scripts": {
    "dev": "parcel public/index.html --open --port 3000",
    "start": "parcel public/index.html --open --port 3000",
    "api": "openapi-server",
    "build": "parcel build public/index.html",
    "build-devtools": "parcel build --no-optimize public/index.html",
    "build:api": "openapi-generator generate -i api.yml -g typescript-axios -o src/generated/axios/",
    "test": "jest",
    "tdd": "jest --watch --verbose false",
    "coverage": "jest --coverage",
    "install:types": "types-installer install",
    "intl": "node scripts/intl",
    "email-build": "node scripts/email-build",
    "clean": "rm -rf node_modules/ .cache/ dist/",
    "reinstall": "npm run clean && npm i --no-optional",
    "lint": "eslint --ext .js --ext .ts --ext .jsx --ext .tsx src",
    "lint:fix": "eslint --ext js,ts,jsx,tsx --fix --cache src",
    "cy:verify": "cypress verify",
    "cy:version": "cypress version",
    "cy:run": "cypress run",
    "cy:open-config": "tsc --outDir cypress src/config/paths.ts",
    "cy:open": "npm run cy:open-config && cypress open",
    "cy:run-config": "npm run cy:open-config && cypress run"
  },
  "files": [
    "dist/**/*"
  ],
  "devDependencies": {
    "@openapitools/openapi-generator-cli": "0.0.9-3.3.0",
    "@parcel/config-default": "^2.9.3",
    "@parcel/transformer-less": "^2.9.3",
    "@parcel/transformer-sass": "^2.9.3",
    "@parcel/transformer-typescript-tsc": "^2.9.3",
    "@types/enzyme": "^3.10.14",
    "@types/enzyme-adapter-react-16": "^1.0.7",
    "@types/file-saver": "^2.0.5",
    "@types/jest": "^29.5.5",
    "@types/lodash": "^4.14.199",
    "@types/node": "^18.19.31",
    "@types/numeral": "^0.0.26",
    "@types/qs": "^6.9.8",
    "@types/react": "^16.14.48",
    "@types/react-beautiful-dnd": "^13.1.5",
    "@types/react-dom": "^16.9.20",
    "@types/react-helmet": "^5.0.22",
    "@types/react-intl": "^2.3.18",
    "@types/react-router": "^5.1.20",
    "@types/react-router-dom": "^5.3.3",
    "@types/redux-mock-store": "^1.0.4",
    "@typescript-eslint/eslint-plugin": "^6.7.4",
    "@typescript-eslint/parser": "^6.7.4",
    "buffer": "^6.0.3",
    "dotenv": "^16.3.1",
    "enzyme": "^3.11.0",
    "enzyme-adapter-react-16": "^1.15.7",
    "enzyme-to-json": "^3.6.2",
    "eslint": "^8.51.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-config-react": "^1.1.7",
    "eslint-plugin-prettier": "^5.0.0",
    "eslint-plugin-react": "^7.33.2",
    "eslint-plugin-react-hooks": "^4.6.0",
    "express": "^4.18.2",
    "http-proxy-middleware": "^2.0.6",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-transform-stub": "^2.0.0",
    "less": "^4.2.0",
    "openapi-server": "git+https://gitlab.intesys.it/open-source/openapi-server.git",
    "parcel": "2.9.3",
    "parcel-transformer-tsc-sourcemaps": "^1.0.2",
    "prettier": "^3.0.3",
    "process": "^0.11.10",
    "punycode": "^1.4.1",
    "react-intl-extract": "git+https://gitlab.intesys.it/open-source/react-intl-extract.git",
    "redux-mock-store": "^1.5.4",
    "sass": "^1.68.0",
    "ts-jest": "^29.1.1",
    "tslib": "^2.6.2",
    "types-installer": "^1.6.3",
    "typescript": "^5.2.2",
    "url": "^0.11.3"
  },
  "volta": {
    "node": "18.18.0"
  }
}
