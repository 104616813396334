import { useEffect } from "react";
import { getApplicationTypes } from "../../../../shared/store/applicationType/actions";
import { useAppDispatch } from "../../../../shared/store/hooks";

interface Props {
  cond: boolean;
}

export function LoadApplicationTypes({ cond }: Readonly<Props>) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (cond) dispatch(getApplicationTypes());
  }, [cond, dispatch]);
  return null;
}
